*, ::before, ::after {
    box-sizing: border-box;
    scroll-behavior: smooth;
    line-height:var(--line-height);
    background-repeat: no-repeat;
}
html {
    word-break:break-word;
    overflow-wrap:break-word;
    font-family:Arial, Helvetica, sans-serif;
    -moz-tab-size: 4;
	tab-size: 4;
    --line-height: calc(.7em + .8rem);
	/* consistent y-scrollbar on all pages and on dynamic content
	when scrollbar-gutter is supported test and use:
	overflow-y: auto;
	scrollbar-gutter: stable;
	*/
	overflow-y: scroll;
    min-block-size: 100%;
}
body {
    margin:auto;
    min-block-size: 100%;
}
h1, h2, h3, h4, h5, h6 {
    margin-top:1em;
    margin-bottom:1em;
}
hr {
    border-style:solid;
    border-width:0 0 1px 0;
    border-top-width:0 !important;
}
img, svg, video, canvas {
    max-width:100%;
    object-fit:cover;
    height: auto;
}

/* table */
table {
    border-collapse: collapse;
    border-spacing: 0; /* if you don't want to collapse, the border-spacing defaults to 0 */
	font-variant-numeric: tabular-nums;
}
th { text-align: inherit; }

audio, video { width: 100%; }
video { background-color:#aaa; }
iframe { width:100%; }

/* nav */
nav a { text-decoration:none; } /* display:block? color:inherit? */
nav li { list-style: none; }
nav ul { padding-left:1.5em; }
nav > ul { padding-left:0; }

/* clickable */
select, summary, label, button:not([disabled]), [type=checkbox], [type=radio], [type=file], .btn {
    cursor:pointer;
}
/* form */
input, textarea, select, button, .btn {
    padding:.2em;
    border:1px solid rgba(0,0,0,.5);
    font: inherit; /* inherits line-height which is stronger than `* { line-height:var(--line-height); }` */
    /*
    line-heigth:
    try to match immutable select-line-height
    in chrome:1.22, in ff:1.17 (best fit line-height:1.19?)
    "normal" does not work in chrome unlike appereance:none
    --line-height:normal;
    */
    line-height:normal;
}
button, .btn {
    padding-left:.7em;
    padding-right:.7em;
}
textarea { resize: vertical; }
input, textarea { width:13rem; }
[type=radio], [type=checkbox], [type=file] { width:auto; }  /* todo: incomplete */
